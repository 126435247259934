function GoogleAnalytics() {}

GoogleAnalytics.productImpression = function (elm, productData) {
    const eventName = 'ProductImpressions'
    const productImpressionEvent = dataLayer.find(item => item.event === eventName)
    if (!productImpressionEvent) {
        dataLayer.push({
            'event': eventName,
            'currencyCode': 'EUR',
            'ecommerce': {
                'impressions': [
                    productData
                ]
            }
        })
    } else {
        productImpressionEvent.ecommerce.impressions.push(productData)
    }
}

GoogleAnalytics.productClick = function (elm, productData, position) {
    productData.position = position
    dataLayer.push({
        'event': 'productClick',
        'currencyCode': 'EUR',
        'ecommerce': {
            'click': {
                'actionField': {'list':  'Assortiment'},
                'products': [
                    productData
                ]
            }
        },
        'eventCallback': function () {
            document.location = elm.href
        }
    })
}

GoogleAnalytics.addToCart = function (elm, productData) {
    dataLayer.push({
        'event': 'addToCart',
        'currencyCode': 'EUR',
        'ecommerce': {
            'add': {
                'products': [
                    productData
                ]
            }
        }
    })
}

GoogleAnalytics.removeFromCart = function (productData) {
    dataLayer.push({
        'event': 'removeFromCart',
        'currencyCode': 'EUR',
        'ecommerce': {
            'remove': {
                'products': [
                    productData
                ]
            }
        }
    })
}

GoogleAnalytics.checkout = function (checkoutData) {
    dataLayer.push(checkoutData)
}

export default GoogleAnalytics
