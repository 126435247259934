import 'regenerator-runtime/runtime'
import 'core-js/stable' // @todo: still required??
import swal from 'sweetalert2'
import GoogleAnalytics from './includes/google-analytics'
import LazyLoad from 'vanilla-lazyload/dist/lazyload'

import Tether from 'tether'
import Popper from 'popper.js'
import 'slick-carousel/slick/slick'
import 'select2'
import 'select2/dist/js/i18n/nl'
import Cookies from 'js-cookie'
import 'jquery.fancytable/dist/fancyTable.min'

// Load CSS files
import './../scss/_includes/_bootstrap22.scss'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './../scss/_includes/_font-awesome.scss'
import 'select2/dist/css/select2.css'
import 'tether/dist/css/tether.css'
import 'sweetalert2/dist/sweetalert2.css'

// Add packages to window scope.
global.$ = global.jQuery = $;
global.swal = swal;
global.GoogleAnalytics = GoogleAnalytics;
global.LazyLoad = LazyLoad;
global.Tether = Tether
global.Popper = Popper
global.Cookies = Cookies


/**
 * @todo: Replace bootstrap hack..
 */
global.Util = require('exports-loader?Util!bootstrap/js/dist/util');
global.Tooltip = require('exports-loader?Tooltip!bootstrap/js/dist/tooltip');
global.Dropdown = require('exports-loader?Tooltip!bootstrap/js/dist/dropdown');
global.Popover = require('exports-loader?Popover!bootstrap/js/dist/popover');
